import styled from "styled-components";
import * as React from "react";
import { BottomNavSegment } from "../components/PageSegments/BottomNav";
import { BasicLayout } from "../layouts/BasicLayout";
import SpecialOfferModal from "../components/Modal/SpecialOfferModal";
import {
  SpecialOfferBar,
  HeroSegment,
  AppPitchSegment,
  TestimonySegment,
} from "../components/PageSegments/special-offer/landing";
import { useOnboardingContext } from "../components/PageSegments/onboarding/OnboardingContext";
import { web_acq_landing_page_view } from "../services/mixpanel/acquisition-events";
import { fbq } from "../services/pixel";
import { setMixpanelProperties } from "../services/mixpanel/mixpanel";
import { discountVanityUrlsMap } from "../util/vanity";
import { LocalPriceContext } from "../providers/LocalPriceProvider";
interface PageContext {
  node: {
    group_name: string;
    route: string;
    utm_campaign: string;
    utm_channel: string;
    utm_source: string;
    utm_medium: string;
    utm_content: string;
  };
}

export default ({ pageContext }: { pageContext: PageContext }) => {
  const { node } = pageContext;
  const { group_name, route } = node;
  const { setOnboardingLandingPage, setCustomGroup, setEnableGeolocation } = useOnboardingContext();

  const hasSpecialDiscount = discountVanityUrlsMap.has(route);

  const { setSpecialDiscount } = React.useContext(LocalPriceContext);

  React.useEffect(() => {
    localStorage.setItem(
      "utmData",
      JSON.stringify({
        utm_campaign: node.utm_campaign,
        utm_channel: node.utm_channel,
        utm_source: node.utm_source,
        utm_medium: node.utm_medium,
        utm_content: node.utm_content,
      }),
    );

    setMixpanelProperties(
      {
        "UTM source": node.utm_source,
        "UTM medium": node.utm_medium,
        "UTM campaign": node.utm_campaign,
        "UTM content": node.utm_content,
        "UTM channel": node.utm_channel,
      },
      true,
    );

    web_acq_landing_page_view();
    fbq("trackCustom", "landing_page_view");
    setCustomGroup(group_name);
    setEnableGeolocation(true);
    setOnboardingLandingPage("web_discount_flow");
  }, [setOnboardingLandingPage, setCustomGroup, node]);

  React.useEffect(() => {
    setSpecialDiscount(hasSpecialDiscount);
  }, [hasSpecialDiscount, setSpecialDiscount]);

  return (
    <BasicLayout noIndex>
      <Main>
        <SpecialOfferBar specialDiscount={hasSpecialDiscount} customGroup={group_name} />
        <HeroSegment specialDiscount={hasSpecialDiscount} />
        <TestimonySegment />
        <AppPitchSegment />
        <BottomNavSegment />
        <SpecialOfferModal specialDiscount={hasSpecialDiscount} customGroup={group_name} />
      </Main>
    </BasicLayout>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
